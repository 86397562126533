var exports = {};
exports.__esModule = true;
var ch2 = {};

exports["default"] = function (c, id, msg, transfer, cb) {
  var u = ch2[id] || (ch2[id] = URL.createObjectURL(new Blob([c], {
    type: "text/javascript"
  })));
  var w = new Worker(u);

  w.onerror = function (e) {
    return cb(e.error, null);
  };

  w.onmessage = function (e) {
    return cb(null, e.data);
  };

  w.postMessage(msg, transfer);
  return w;
};

export default exports;
export const __esModule = exports.__esModule;